<template>
  <div>
    <div class="myIndex">
      <!-- 我的钱包 -->
      <div class="py-25 flex align-center border border-EEEEEE mb-10">
        <div class="px-40 border-right border-E5E5E5 x-1">
          <div class="flex align-center justify-between mb-35">
            <div
              class="font-23 font-weight-bolder text-333333 cursorp btn2"
              @click="gomyPurse"
            >
              我的钱包
            </div>
            <div class="font-16 text-666666 cursorp" @click="gomyDetail">我的明细</div>
          </div>
          <div class="font-14 text-999999 mb-10">余额</div>
          <div class="flex align-center">
            <div class="font-42 text-FE5A09 mr-55">
              {{ info.balance }} <span class="font-20">元</span>
            </div>
            <div class="myIndex_btn1" @click="gotopUp">立即充值</div>
            <div class="myIndex_btn2" @click="gowithdrawdeposit">申请提现</div>
          </div>
        </div>
        <div class="px-20 myIndex_right">
          <!-- <div class="mb-25 flex align-center">
          <img src="@/assets/img/zhufubao.png" width="1.625rem" height="1.625rem" alt="" />
          <span class="ml-15 text-666666" v-if="info.is_ali == 1">已绑定</span>
          <span class="ml-15 text-666666" v-else>未绑定</span>
        </div>
        <div class="mb-25 flex align-center">
          <img src="@/assets/img/weixin.png" width="1.625rem" height="1.625rem" alt="" />
          <span class="ml-15 text-666666" v-if="info.is_wx == 1">已绑定</span>
          <span class="ml-15 text-666666" v-else>未绑定</span>
        </div> -->
          <div class="flex align-center cursorp" @click="gocardList">
            <!-- <img
              src="@/assets/img/card.png"
              width="1.625rem"
              height="1.625rem"
              alt=""
            /> -->
            <el-image
              style="width: 1.625rem; height: 1.625rem"
              :src="require('@/assets/img/card.png')"
              fit="cover"
            ></el-image>
            <span class="ml-15 text-666666" v-if="info.is_bank == 1">已绑定 </span>
            <span class="ml-15 text-666666" v-else>未绑定</span>
            <span @click.stop="show3 = true" class="ml-15" v-if="info.is_bank == 1"
              >解绑</span
            >
          </div>
        </div>
      </div>
      <!-- 个人信息修改 -->
      <div class="myIndex_info border border-EEEEEE">
        <div class="flex align-center justify-between mb-10">
          <div class="myIndex_info_title font-14 text-333333">昵称</div>
          <div>
            <el-input v-model="username" placeholder="请输入昵称"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-10">
          <div class="myIndex_info_title font-14 text-333333">手机号</div>
          <div>
            <el-input v-model="phone" type="number" placeholder="请输入手机号"></el-input>
          </div>
        </div>
        <div class="flex align-center justify-between mb-25">
          <div class="myIndex_info_title font-14 text-333333">生日</div>
          <div class="cursorp">
            <el-date-picker
              v-model="birthday"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              :clearable="false"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="flex align-center justify-between mb-30">
          <div class="myIndex_info_title font-14 text-333333">性别</div>
          <div class="flex align-center">
            <div
              v-for="(item, index) in sexList"
              :key="index"
              class="font-14 text-999999 flex align-center mr-20 cursorp"
              @click="sexcut(item.id)"
            >
              <!-- <img
                src="@/assets/img/sele_act.png"
                width="1.3125rem"
                height="1.3125rem"
                alt=""
                v-if="item.id == sex"
                class="mr-10"
              /> -->
              <el-image
                v-if="item.id == sex"
                class="mr-10"
                style="width: 1.3125rem; height: 1.3125rem"
                :src="require('@/assets/img/sele_act.png')"
                fit="cover"
              ></el-image>
              <el-image
                v-else
                class="mr-10"
                style="width: 1.3125rem; height: 1.3125rem"
                :src="require('@/assets/img/sele.png')"
                fit="cover"
              ></el-image>
              <!-- <img
                src="@/assets/img/sele.png"
                width="1.3125rem"
                height="1.3125rem"
                alt=""
                v-else
                class="mr-10"
              /> -->
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="flex align-center justify-between mb-25">
          <div class="myIndex_info_title font-14 text-333333">地区</div>
          <div>
            <el-cascader
              size="large"
              :options="optionsArr"
              v-model="selectedOptions"
              :props="{ value: 'label' }"
              @change="handleChange"
            >
            </el-cascader>
          </div>
        </div>
        <!-- <div class="flex align-center justify-between mb-25">
        <div class="myIndex_info_title font-14 text-333333">微信</div>
        <div class="font-14 text-999999 cursorp">
          <div v-if="info.is_wx == 1">
            已绑定：{{ info.wx_name }} <span class="ml-5 text-FF524C">修改</span>
          </div>
          <div v-else>去绑定</div>
        </div>
      </div>
      <div class="flex align-center justify-between mb-25">
        <div class="myIndex_info_title font-14 text-333333">支付宝</div>
        <div class="font-14 text-999999 cursorp">
          <div v-if="info.is_ali == 1">
            已绑定：{{ info.ali_name }} <span class="ml-5 text-FF524C">修改</span>
          </div>
          <div v-else>去绑定</div>
        </div>
      </div> -->
        <div class="flex align-center justify-between mb-25" @click="gocardList">
          <div class="myIndex_info_title font-14 text-333333">银行卡</div>
          <div class="font-14 text-999999 cursorp">
            <div v-if="info.is_bank == 1">
              已绑定 <span class="ml-15" @click.stop="show3 = true">解绑</span>
            </div>
            <div v-else>去绑定</div>
          </div>
        </div>
        <div class="btn" @click="amendBnt">确定修改</div>
      </div>
      <!-- 解绑 -->
    </div>
    <el-dialog :visible.sync="show3" width="30%" center>
      <div class="p-20 jbdialog">
        <div class="flex align-center justify-between font-14 py-15">
          <div class="text-333333">开户银行名称</div>
          <div class="text-666666">
            {{ account_info.bank_name }}
          </div>
        </div>
        <div class="flex align-center justify-between font-14 py-15">
          <div class="text-333333">卡号</div>
          <div class="text-666666">
            {{ account_info.card_id }}
          </div>
        </div>
        <div @click="jiebang" class="mt-30 btn">解绑银行卡</div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="show2" width="30%" center>
      <div class="p-20 jbdialog">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="phone" v-if="type == 2">
            <el-input
              type="number"
              v-model="ruleForm.phone"
              autocomplete="off"
              placeholder="手机号"
              prefix-icon="el-icon-mobile-phone"
              @keyup.enter.native="unbindcard"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone2" v-else>
            <el-input
              type="number"
              v-model="ruleForm.phone2"
              autocomplete="off"
              placeholder="手机号"
              prefix-icon="el-icon-mobile-phone"
              @keyup.enter.native="unbindcard"
            >
              <el-button
                slot="suffix"
                type="text"
                size="mini"
                style="border: none"
                @click="sendcode"
                :disabled="!show"
              >
                获取验证码
                <span v-show="!show" class="count">({{ count }}s)</span>
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item prop="code" v-if="type == 1">
            <el-input
              type="text"
              v-model="ruleForm.code"
              autocomplete="off"
              placeholder="验证码"
              prefix-icon="el-icon-folder-checked"
              @keyup.enter.native="unbindcard"
            ></el-input>
          </el-form-item>
          <el-form-item prop="pass" v-if="type == 2">
            <el-input
              type="password"
              v-model="ruleForm.pass"
              autocomplete="off"
              placeholder="密码"
              prefix-icon="el-icon-lock"
              @keyup.enter.native="unbindcard"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="btn" @click="unbindcard">解绑银行卡</div>
          </el-form-item>
          <el-form-item>
            <div class="flex align-center justify-between">
              <div
                class="font-14 text-333333 cursorp"
                v-if="type == 1"
                @click="cuttype(2)"
              >
                账号密码解绑
              </div>
              <div
                class="font-14 text-333333 cursorp"
                v-if="type == 2"
                @click="cuttype(1)"
              >
                验证码解绑
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
import req from "../../../utils/req";
export default {
  data() {
    var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    var validatephone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!reg_tel.test(value)) {
        callback(new Error("请输入正确手机号"));
      } else {
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };

    return {
      type: 1,
      ruleForm: {
        phone: "",
        phone2: "",
        code: "",
        pass: "",
      },
      show: true, //控制按钮
      rules: {
        phone: [{ validator: validatephone, trigger: "blur" }],
        phone2: [{ validator: validatephone, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
      },
      count: "", //倒计时
      show3: false,
      show2: false,
      optionsArr: regionData,
      selectedOptions: [],
      sexList: [
        {
          id: "男",
          name: "男",
        },
        {
          id: "女",
          name: "女",
        },
      ],
      info: "",
      username: "",
      avatar: "", //相对
      avatarUrl: "", //绝对
      sex: "",
      birthday: "",
      phone: "",
      address: "",
      account_info: "",
    };
  },
  mounted() {
    this.getdata();
    this.getInfo();
  },
  methods: {
    // 跳转我的明细
    gomyDetail() {
      this.$router.push({
        path: "/myDetail",
        query: {type: "myDetail",},
      });
    },
    // 跳转我的钱包
    gomyPurse() {
      this.$router.push({
        path: "/myPurse",
        query: { type: "my", mynavid: 1 },
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 切换type
    cuttype(id) {
      this.type = id;
      this.resetForm("ruleForm");
      this.show = true;
      clearInterval(this.timer); // 清除定时器
      this.timer = null;
      this.count = "";
    },
    //发送验证码
    sendcode() {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let that = this;
      if (!that.ruleForm.phone2) {
        that.$message.error("请输入手机号");
        return;
      } else if (!reg_tel.test(that.ruleForm.phone2)) {
        that.$message.error("请输入正确手机号");
        return;
      }
      let type = 1;
      if (that.type == 2) {
        type = 2;
      } else if (this.type == 3) {
        type = 1;
      } else if (this.type == 4) {
        type = 2;
      }
      req
        .post("auth/getCode", {
          type: type,
          mobile: that.ruleForm.phone2,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          this.$message.success(res.msg);
          if (!that.timer) {
            that.count = 60;
            that.show = false;
            that.timer = setInterval(() => {
              if (that.count > 0 && that.count <= 60) {
                that.count--;
              } else {
                that.show = true;
                clearInterval(that.timer); // 清除定时器
                that.timer = null;
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    jiebang() {
      this.show2 = true;
      this.show3 = false;
    },
    // 解绑银行卡
    unbindcard() {
      let that = this;
      // req
      //   .post("auth/bankCode", {})
      //   .then((res) => {
      //     if (res.code != 200) {
      //       return;
      //     }

      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      let mobile = "";
      if (that.type == 1) {
        mobile = that.ruleForm.phone2;
      } else {
        mobile = that.ruleForm.phone;
      }
      req
        .post("auth/delBank", {
          type: that.type,
          password: that.ruleForm.pass,
          mobile: mobile,
          code: that.ruleForm.code,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success("解绑成功");
          this.show2 = false;
          setTimeout(() => {
            that.getdata();
            that.getInfo();
          }, 600);
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 跳转充值
    gotopUp() {
      this.$router.push({
        path: "/topUp",
        query: {type: "topUp",},
      });
    },
    // 跳转提现
    gowithdrawdeposit() {
      this.$router.push({
        path: "/withdrawdeposit",
        query: {type: "withdrawdeposit",},
      });
    },
    // 跳转银行卡列表
    gocardList() {
      if (this.info.is_bank != 1) {
        this.$router.push({
          path: "/cardbinding2",
          query: {type: "cardbinding2",},
        });
      }
    },
    // 确定修改
    amendBnt() {
      let that = this;
      req
        .post("center/editPersonal", {
          avatar: that.avatar,
          username: that.username,
          sex: that.sex,
          birthday: that.birthday,
          address: that.address,
          phone: that.phone,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          this.$router.go(0);
          this.getdata();
          this.getInfo();
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 切换性别
    sexcut(id) {
      this.sex = id;
    },
    // 切换城市
    handleChange(value) {
      console.log("地址", value);
      this.address = value[0] + "-" + value[1] + "-" + value[2];
    },
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("center/my", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.info = res.data;
          req
            .post("auth/bankCode", {})
            .then((res) => {
              if (res.code != 200) {
                // that.$message.error(res.msg);
                return;
              }
              that.account_info = res.data.account_info;
            })
            .catch((err) => {
              console.log(err);
              // that.$message.error(err.msg);
            });
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    // 获取个人资料
    getInfo() {
      let that = this;
      req
        .post("center/personal", {})
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.username = res.data.username;
          that.phone = res.data.phone;
          that.avatar = res.data.avatar;
          that.avatarUrl = res.data.avatar_url;
          that.sex = res.data.sex;
          that.birthday = res.data.birthday;
          that.address = res.data.address;
          let arr = res.data.address.split("-");
          that.selectedOptions = arr;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.myIndex {
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::v-deep .el-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    border-radius: 0;
    border: 0;
    box-sizing: border-box;
    color: #999999;
    display: inline-block;
    font-size: 0.875rem;
    height: 2.5rem;
    line-height: 2.5rem;
    outline: 0;
    text-align: right;
    padding: 0 0.9375rem;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }
  ::v-deep .el-icon-arrow-down:before {
    content: "";
  }
  ::v-deep .el-icon-date:before {
    content: "";
  }
  ::v-deep .el-icon-date:before {
    content: "";
  }
  &_info {
    padding: 2.375rem 2.375rem 2rem;
    padding-right: 25rem;
    &_title {
      width: 3.125rem;
    }
  }
  &_right {
    width: 13.8125rem;
    box-sizing: border-box;
  }
  .btn {
    width: 7.6875rem;
    height: 2.1875rem;
    background: #ff524c;
    border-radius: 1.125rem;
    color: #ffffff;
    line-height: 2.1875rem;
    text-align: center;
    font-size: 0.875rem;
    margin: 0 auto;
    margin-top: 1.875rem;
    cursor: pointer;
  }
  .btn2 {
    width: 7.6875rem;
    height: 2.1875rem;
    background: #ff524c;
    border-radius: 1.125rem;
    color: #ffffff;
    line-height: 2.1875rem;
    text-align: center;
    font-size: 0.875rem;
    // margin: 0 auto;
    // margin-top: 1.875rem;
    cursor: pointer;
  }
  &_btn1 {
    width: 5.8125rem;
    height: 2.1875rem;
    background: linear-gradient(-90deg, #0f7bad, #0e58bd);
    box-shadow: 0 0.25rem 0.4375rem 0 rgba(17, 110, 169, 0.18);
    border-radius: 1.125rem;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 2.1875rem;
    text-align: center;
    margin-right: 1.875rem;
    cursor: pointer;
  }
  &_btn2 {
    width: 5.8125rem;
    height: 2.1875rem;
    background: linear-gradient(-90deg, #fe9309, #fe5509);
    box-shadow: 0 0.25rem 0.4375rem 0 rgba(254, 85, 9, 0.18);
    border-radius: 1.125rem;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 2.1875rem;
    text-align: center;
    cursor: pointer;
  }
}
.jbdialog {
  .btn {
    width: 7.6875rem;
    height: 2.1875rem;
    background: #ff524c;
    border-radius: 1.125rem;
    color: #ffffff;
    line-height: 2.1875rem;
    text-align: center;
    font-size: 0.875rem;
    margin: 0 auto;
    // margin-top: 1.875rem;
    cursor: pointer;
  }
  ::v-deep .el-button--text {
    border-color: transparent;
    color: #222222;
    background: 0 0;
    padding-left: 0;
    padding-right: 0;
  }
  ::v-deep .el-input__inner {
    -webkit-appearance: none;
    border: 0;
    box-sizing: border-box;
    color: #000000;
    display: inline-block;
    font-size: inherit;
    line-height: 2.75rem;
    outline: 0;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 2.75rem;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 1.375rem;
  }
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  ::v-deep input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
